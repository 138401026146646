<template>
  <div v-if="contents">
    <component
      v-for="(item, i) in contents" :key="i"
      :is="getCmp(item.type)"
      :config="item"
    >
      <component
        v-for="(sub, s) in item.contents" :key="'s' + s"
        :is="getCmp(sub.type)"
        :config="sub">
      </component>
    </component>
  </div>
</template>

<script>
export default {
  name: 'ContentRoot',
  components: {
    ContentAudio: () => import('./ContentAudio.vue'),
    ContentCarousel: () => import('./ContentCarousel.vue'),
    ContentExpand: () => import('./ContentExpand.vue'),
    ContentForm: () => import('./ContentForm.vue'),
    ContentGame: () => import('./ContentGame.vue'),
    ContentHtml: () => import('./ContentHtml.vue'),
    ContentIframe: () => import('./ContentIframe.vue'),
    ContentImage: () => import('./ContentImage.vue'),
    ContentQuiz: () => import('./ContentQuiz.vue'),
    ContentSmartvue: () => import('./ContentSmartvue.vue'),
    ContentSpace: () => import('./ContentSpace.vue'),
    ContentText: () => import('./ContentText.vue'),
    ContentVideo: () => import('./ContentVideo.vue')
  },
  props: {
    contents: Array,
    executeScripts: Boolean
  },
  provide () {
    return {
      executeScripts: this.executeScripts
    }
  },
  methods: {
    getCmp (type) {
      let typeUpper = type.charAt(0).toUpperCase() + type.slice(1)
      return 'Content' + typeUpper
    }
  }
}
</script>
