<template>
  <div>
    <h2 class="mb-5 font-weight-bold">{{ $t('selfservice') }}</h2>
    <div class="mb-8">{{ $t('selfservice_text') }}</div>

    <v-expansion-panels class="expand">
      <v-expansion-panel class="tourify-green-bg">
        <v-expansion-panel-header>
          <div>{{ $t('selfservice_unlock_posts') }}</div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-container>
            <v-form v-model="valid">
              <v-select
                v-if="tour.posts_order_force"
                v-model="postNr"
                :items="tour ? tour.posts.map((p, i) => ({
                  text: (i + 1) + ': ' + p.title,
                  value: (i + 1)
                })) : []"
                :label="$t('posts_including')"
                :rules="[v => !!v]"
              />
              <v-select
                v-else
                v-model="postNrs"
                :items="tour ? tour.posts.map((p, i) => ({
                  text: (i + 1) + ': ' + p.title,
                  value: (i + 1)
                })) : []"
                :label="$t('posts')"
                :rules="[v => !!v && v.length > 0]"
                multiple
              />
              <div class="font-italic my-5">{{ $t('selfservice_unlock_posts_reason') }}</div>
              <v-textarea v-model="reason" :rules="[v => !!v || $t('field_required')]" outlined/>
              <v-text-field v-model="email" :label="$t('email')" :rules="[v => v && v.length > 4 && v.indexOf('@') > 0 || $t('field_required')]"/>
              <v-text-field v-model="tel" :label="$t('tel')" :rules="[v => v && v.length > 5 || $t('field_required')]"/>
              <div class="text-center mt-5">
                <v-btn :disabled="!valid" :loading="loading" @click="recover">{{ $t('submit') }}</v-btn>
              </div>
            </v-form>
          </v-container>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
export default {
  name: 'SelfService',
  data () {
    return {
      postNr: null,
      postNrs: null,
      reason: null,
      email: null,
      tel: null,
      loading: false,
      valid: false
    }
  },
  computed: {
    tour () {
      return this.$store.state.tour
    }
  },
  methods: {
    async recover () {
      this.loading = true
      await this.$store.dispatch('recoverState', {
        nr: this.postNr,
        nrs: this.postNrs,
        reason: this.reason,
        email: this.email,
        tel: this.tel
      })
      this.loading = false
      this.$emit('close')
    }
  },
  created () {
    if (this.$store.state.user?.email) {
      this.email = this.$store.state.user.email
    }
  }
}
</script>