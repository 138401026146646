/**
 * file and image functions, CDN support
 */
const DEFAULT_IMG_WIDTH = 800

const LIVE = location.hostname.indexOf('test') === -1
const IMG_BASE_URL = process.env['VUE_APP_IMG_BASE_URL' + (LIVE ? '' : '_TEST')]
const FILE_BASE_URL = process.env['VUE_APP_FILE_BASE_URL' + (LIVE ? '' : '_TEST')]

export default {
  DEFAULT_IMG_WIDTH: DEFAULT_IMG_WIDTH,
  methods: {
    fileSrc (slug) {
      if (!slug) return '' // nothing
      if (slug.substr(0, 4) === 'http') return slug // old full urls
      return FILE_BASE_URL + slug.replace(/\//g, '%2F') + '?alt=media' // new CDN compatible slugs
    },
    imgSrc (slug, width = 0) {
      if (!slug) return '' // nothing
      if (slug.substr(0, 4) === 'http') return slug // old full urls
      // imgix or gcs
      if (IMG_BASE_URL.indexOf('imgix') > -1) {
        // if offline, fall back to cached default sized image
        if (!width && !this.$store.state.isOnline) {
          width = DEFAULT_IMG_WIDTH
        }
        // width only if not gif
        return IMG_BASE_URL + slug + (width && slug.indexOf('.gif') === -1 ? ('?w=' + width) : '')
      } else {
        return IMG_BASE_URL + slug.replace(/\//g, '%2F') + '?alt=media'
      }
    },
    imgSrcLazy (slug) {
      if (!slug) return '' // nothing
      // if offline, don't provide lazy src (fall back to cached default image)
      return this.$store.state.isOnline ? this.imgSrc(slug, 16) : null
    },
    imgSrcSet (slug, widths = [400, 800, 1200]) {
      if (!slug) return '' // nothing
      // SrcSet only will work with IMGIX and when online (otherwise fall back to cached default sized image) and not gif
      if (this.$store.state.isOnline && IMG_BASE_URL.indexOf('imgix') > -1 && slug.indexOf('.gif') === -1) {
        const src = this.imgSrc(slug)
        if (src) {
          return widths.map(w => {
            return src + '?w=' + w + ' ' + w + 'w'
          }).join(', ')
        }
      }
    }
  }
}
