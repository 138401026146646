<template>
  <div v-if="!v2">
    <div v-if="obj.author_name" class="author-info">
      {{ $t('made_by') }} <a :href="obj.author_link" target="_blank">{{ obj.author_name}}</a>
      <br v-if="$vuetify.breakpoint.smAndDown">
      {{ $t('created_with') }} <a href="https://tourify.ch/smartrails" target="_blank">smarTrails</a><sup>&reg;</sup>
    </div>
  </div>
  <div v-else>
    <div v-if="obj.author_name" class="author-info">
      <div class="mb-2">{{ $t('made_by_2') }} <a :href="obj.author_link" target="_blank">{{ obj.author_name}}</a></div>
      <div v-html="$t('created_with_2')"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuthorInfo',
  props: {
    provider: Object,
    tour: Object,
    v2: Boolean
  },
  computed: {
    obj () {
      return this.provider || this.tour
    }
  }
}
</script>

<style scoped>
.author-info {
  text-align: center !important;
}
</style>
