<template>
  <v-snackbar v-model="show" bottom color="info" :timeout="-1">
    <div>{{ $t('update_available') }}</div>
    <template v-slot:action>
      <v-btn text @click="update">OK</v-btn>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: 'UpdateInfo',
  data () {
    return {
      registration: null,
      show: false
    }
  },
  methods: {
    update () {
      this.show = false
      // install
      if (this.registration?.waiting) {
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        // notify
        this.$emit('installing')
      }
    }
  },
  created () {
    // listen to sw updates
    document.addEventListener('swUpdated', (event) => {
      this.registration = event.detail
      this.show = true
    })

    // may not be available in private mode or local
    if (navigator.serviceWorker !== undefined) {
      let reloading = false
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (reloading) return
        reloading = true
        window.location.reload()
      })
    }
  }
}
</script>
