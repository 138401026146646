<template>
  <v-card :to="to" :href="href" :target="href ? '_blank' : undefined" class="imagecard">
    <v-img :src="imgSrc(src)" :lazy-src="imgSrcLazy(src)" :srcset="imgSrcSet(src)" :aspect-ratio="16/9" height="200">
      <v-container class="overlay fill-height">
        <div class="headline white--text">
          {{ title }}
        </div>
        <div class="three-lines white--text">
          {{ desc }}
        </div>
      </v-container>
    </v-img>
  </v-card>
</template>

<script>
import FileMixin from '@/mixins/FileMixin.js'

export default {
  name: 'ImageCard',
  mixins: [FileMixin],
  props: {
    title: String,
    desc: String,
    src: String,
    to: String,
    href: String
  }
}
</script>

<style scoped>
.imagecard {
  height: 200px;
  overflow: hidden;
  background: var(--v-primary-lighten3);
  text-align: center;
}
.overlay {
  overflow: hidden;
  background-image: linear-gradient(to bottom, transparent, black);
  background-position-y: 25px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.overlay:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay > div {
  margin: 0 1rem 1rem 1rem;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
</style>
