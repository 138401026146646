<template>
  <v-container>
    <!-- <v-row class="text-center">
      <v-col class="py-3" >
        <h1>Demo / Beta App</h1>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col>
        <v-tabs v-model="tab" fixed-tabs color="#333" background-color="transparent">
          <v-tab ripple>{{ $t('providers') }}</v-tab>
          <v-tab ripple>{{ $t('tours') }}</v-tab>
          <v-tabs-items v-model="tab" class="bgc-inherit">
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col v-if="providers.length === 0" class="pa-5 text-center">
                    <v-progress-circular indeterminate color="secondary"/>
                  </v-col>
                  <v-col v-else v-for="provider in providers" :key="provider.slug" cols="12" sm="6" md="4">
                    <image-card :to="'/' + provider.slug" :src="provider.img" :title="provider.title" :desc="provider.desc"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col v-if="tours.length === 0" class="pa-5 text-center">
                    <v-progress-circular indeterminate color="secondary"/>
                  </v-col>
                  <v-col v-else v-for="tour in tours" :key="tour.slug" cols="12" sm="6" md="4">
                    <image-card :to="'/' + tour.slug" :src="tour.img" :title="tour.title" :desc="tour.desc"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import ImageCard from '@/components/ImageCard.vue'

export default {
  name: 'Home',
  components: {
    ImageCard
  },
  data () {
    return {
      tab: 0,
      providers: [],
      tours: []
    }
  },
  computed: {
    locale () {
      return this.$root.$i18n.locale
    }
  },
  methods: {
    read () {
      this.providers = []
      this.tours = []
      axios.get('/api/home/')
        .then(response => {
          this.providers = response.data.providers.map(provider => ({
            slug: provider.slug,
            title: provider['name__' + this.locale] || provider['name__' + provider.langs[0]],
            desc: provider['desc__' + this.locale] || provider['desc__' + provider.langs[0]],
            img: provider['img__' + this.locale] || provider['img__' + provider.langs[0]]
          }))
          this.tours = response.data.tours.map(tour => ({
            slug: tour['slug__' + this.locale] || tour['slug__' + tour.langs[0]],
            title: tour['title__' + this.locale] || tour['title__' + tour.langs[0]],
            desc: tour['desc__' + this.locale] || tour['desc__' + tour.langs[0]],
            img: tour['img__' + this.locale] || tour['img__' + tour.langs[0]]
          }))
        })
    }
  },
  watch: {
    locale () {
      this.read()
    }
  },
  created () {
    this.read()
  }
}
</script>

<style scoped>
.bgc-inherit {
  background-color: inherit !important;
}
</style>
