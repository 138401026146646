import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { fn } from '@/firebase'
import i18n from '@/plugins/i18n'

Vue.use(Vuex)

const userTourFunc = fn.httpsCallable('app-usertour')
const postFunc = fn.httpsCallable('app-post')
const quizFunc = fn.httpsCallable('app-quiz')
const gameFunc = fn.httpsCallable('app-game')
const formFunc = fn.httpsCallable('app-form')
const checkPaymentFunc = fn.httpsCallable('app-checkPayment')
const checkVoucherFunc = fn.httpsCallable('app-checkVoucher')

export default new Vuex.Store({
  state: {
    settings: null,
    provider: null,
    tour: null,
    tourStarted: null, // null: userTour not loaded yet | false | true
    tourStep: '',
    tourPaid: null,
    tourRedeemed: null,
    preventAutoContinue: false,
    quizCount: 0,
    gameCount: 0,
    post: null,
    poi: null,
    snack: null,
    orientation: 'portrait',
    authReady: false, // as soon as fb auth is ready
    user: null, // fb auth
    // userData: { // other user data
    //   // highscore, points, ..
    // },
    userTour: null, // current user-tour data
    // ..started: ts,
    // ..visited: [], // postSlug1, postSlug2, ..
    // GONE: ..answers: {}, // quizSlug: true / false (correct), ..
    // NEW: ..quizzes: { quizSlug: { correct: true/false, submittedAnswer: '', correctAnswer: '' } }
    // ..rated: 0, // 0 = no, 1-5 ranks
    isOnline: window.navigator.onLine,
    imprintDlg: false,
    supportDlg: false
  },
  getters: {
    // is a real user signed in
    isAuthenticated: state => {
      return state.user !== null && !state.user.isAnonymous
    },
    isAdmin: state => {
      if (!state.user) return false
      return (state.provider && state.provider.providerId === state.user.uid) ||
        (state.tour && (state.tour.userId === state.user.uid || state.tour.userIds?.includes(state.user.uid)))
    },
    isPortrait: state => {
      return state.orientation === 'portrait'
    },
    isLandscape: state => {
      return state.orientation === 'landscape'
    },
    postsVisitedCount: state => {
      if (!state.tour || !state.userTour) return 0
      let count = 0
      for (let post of state.tour.posts) {
        if (state.userTour.visited.indexOf(post.slug) > -1) {
          count++
        }
      }
      return count
    },
    tourDone: state => {
      if (!state.tour || !state.userTour) return false
      for (let post of state.tour.posts) {
        if (state.userTour.visited.indexOf(post.slug) === -1) {
          return false
        }
      }
      return true
    },
    // TODO: del
    postUnlockedState: state => (slug) => {
      if (!state.userTour) return false
      return state.userTour.unlocked.indexOf(slug) > -1
    },
    quizItemState: state => (slug) => {
      if (!state.userTour) return false
      return state.userTour.quizzes[slug] !== undefined ? state.userTour.quizzes[slug] : null
    },
    quizProgress: state => {
      if (!state.userTour || !state.quizCount) return null
      const quizzesValues = Object.values(state.userTour.quizzes)
      return {
        total: state.quizCount,
        right: quizzesValues.filter(q => q.correct === true).length,
        wrong: quizzesValues.filter(q => q.correct === false && !q.triesLeft).length
      }
    },
    gameItemState: state => (slug) => {
      if (!state.userTour) return false
      return state.userTour.games[slug] !== undefined ? state.userTour.games[slug] : null
    },
    gameProgress: state => {
      if (!state.userTour || !state.gameCount) return null
      const gameValues = Object.values(state.userTour.games)
      return {
        total: state.gameCount,
        won: gameValues.filter(g => g.won === true).length,
        lost: gameValues.filter(g => g.won === false && !g.triesLeft).length
      }
    },
    formItemState: state => (slug) => {
      if (!state.userTour) return false
      return state.userTour.forms[slug] !== undefined ? state.userTour.forms[slug] : null
    },
  },
  mutations: {
    setSettings (state, settings) {
      state.settings = settings
    },
    setUser (state, { user }) {
      const _user = state.user ? { ...state.user } : null
      if (user) {
        state.user = { ...user }
        if (user.uid !== _user?.uid) {
          state.userTour = null
        }
      } else {
        state.user = null
        state.userTour = null
      }
      state.authReady = user !== null
    },
    setProvider (state, { provider }) {
      state.provider = provider
    },
    setTour (state, { tour }) {
      if (tour) {
        state.tour = tour

        state.tourStarted = null
        state.tourStep = 'welcome'
        state.tourPaid = null
        state.tourRedeemed = null

        // count quizes
        state.quizCount = 0
        state.tour.posts.forEach(post => {
          post.contents.forEach(content => {
            if (content.type === 'quiz') {
              state.quizCount++
            }
            content.contents?.forEach(subcontent => {
              if (subcontent.type === 'quiz') {
                state.quizCount++
              }
            })
          })
        })

        // count games
        state.gameCount = 0
        state.tour.posts.forEach(post => {
          post.contents.forEach(content => {
            if (content.type === 'game') {
              state.gameCount++
            }
            content.contents?.forEach(subcontent => {
              if (subcontent.type === 'game') {
                state.gameCount++
              }
            })
          })
        })
      } else {
        state.tour = null
        state.tourStarted = null
        state.tourStep = ''
        state.tourPaid = null
        state.tourRedeemed = null

        state.quizCount = 0
        state.gameCount = 0
      }
    },
    setUserTour (state, { userTour }) {
      state.userTour = userTour
      // backwards compatibility (for old started tours)
      if (state.userTour && state.userTour.quizzes === undefined) {
        state.userTour.quizzes = {}
      }
      // backwards compatibility (for old started tours)
      if (state.userTour && state.userTour.games === undefined) {
        state.userTour.games = {}
      }
      // backwards compatibility (for old started tours)
      if (state.userTour && state.userTour.unlocked === undefined) {
        state.userTour.unlocked = {}
      }
    },
    setTourStarted (state, payload) {
      state.tourStarted = payload
    },
    setTourStep (state, payload) {
      state.tourStep = payload
    },
    setTourPaid (state, payload) {
      state.tourPaid = payload
    },
    setTourRedeemed (state, payload) {
      state.tourRedeemed = payload
    },
    setPreventAutoContinue (state, payload) {
      state.preventAutoContinue = payload
    },
    setPost (state, { post }) {
      state.post = post
    },
    setPostVisited (state, slug) {
      if (state.userTour.visited.indexOf(slug) === -1) {
        state.userTour.visited.push(slug)
      }
    },
    setPostUnlocked (state, slug) {
      if (state.userTour.unlocked.indexOf(slug) === -1) {
        state.userTour.unlocked.push(slug)
      }
    },
    setPoi (state, { poi }) {
      state.poi = poi
    },
    quizResult (state, { slug, correct, tries, triesLeft, correctAnswer, submittedAnswer, explainCorrectText, explainCorrectImg, explainWrongText, explainWrongImg }) {
      Vue.set(state.userTour.quizzes, slug, {
        correct,
        tries,
        triesLeft,
        correctAnswer,
        submittedAnswer,
        explainCorrectText,
        explainCorrectImg,
        explainWrongText,
        explainWrongImg
      })
    },
    gameResult (state, { slug, won, tries, triesLeft, submittedAnswer, explainCorrectText, explainCorrectImg, explainWrongText, explainWrongImg }) {
      Vue.set(state.userTour.games, slug, {
        won,
        tries,
        triesLeft,
        submittedAnswer,
        explainCorrectText,
        explainCorrectImg,
        explainWrongText,
        explainWrongImg
      })
    },
    formResult (state, { slug, submittedText }) {
      Vue.set(state.userTour.forms, slug, {
        submittedText
      })
    },
    setSnack (state, payload) {
      state.snack = payload
    },
    setOrientation (state, orientation) {
      state.orientation = orientation
    },
    setOnline (state, online) {
      state.isOnline = online
    },
    showImprintDlg (state, show) {
      state.imprintDlg = show
    },
    showSupportDlg (state, show) {
      state.supportDlg = show
    }
  },
  actions: {
    async loadSettings ({ commit, state }) {
      await axios.get('/api/settings/')
        .then(response => {
          let settings = response.data.settings
          commit('setSettings', settings)
        })
    },
    async loadProvider ({ commit, state }, payload) {
      commit('setProvider', { provider: null })
      await axios.get('/api/provider/', {
        params: payload
      })
        .then(response => {
          let provider = response.data.provider
          provider.tours = response.data.tours.map(tour => ({
            slug: tour['slug__' + payload.lang] || tour['slug__' + tour.langs[0]],
            title: tour['title__' + payload.lang] || tour['title__' + tour.langs[0]],
            desc: tour['desc__' + payload.lang] || tour['desc__' + tour.langs[0]],
            img: tour['img__' + payload.lang] || tour['img__' + tour.langs[0]],
            startPos: tour.startPos
          }))
          commit('setProvider', { provider })
        })
        .catch(e => {
          if (e.response && e.response.status === 404) {
            commit('setProvider', { provider: 'notfound' })
          } else {
            commit('setProvider', { provider: 'error' })
          }
        })
    },
    async loadTour ({ commit, state, dispatch }, payload) {
      commit('setTourStep', '')
      commit('setTour', { tour: null })
      await axios.get('/api/tour/', {
        params: payload
      })
        .then(response => {
          commit('setTour', {
            tour: response.data.tour
          })
        })
        .catch(e => {
          if (e.response && e.response.status === 404) {
            commit('setTourStep', 'notfound')
          } else {
            commit('setTourStep', 'error')
          }
        })
    },
    async loadUserTour ({ commit, state }, payload) {
      await userTourFunc({
        tourId: state.tour.tourId,
        tourLang: state.tour.lang,
        ...(payload || {})
      })
        .then(result => {
          commit('setUserTour', {
            userTour: result.data.userTour
          })
          commit('setTourStarted', result.data.userTour !== null)
        })
    },
    async startTour ({ commit, state, dispatch }, payload) {
      await dispatch('loadUserTour', {
        start: true,
        ...(payload || {})
      })
        .then(response => {
          commit('setTourStep', 'main')
        })
        // TODO: handle error (if payment not confirmed (server side))
    },
    async continueTour ({ commit, state, dispatch }, payload) {
      commit('setTourStep', 'main')
      return true
    },
    async restartTour ({ commit, state, dispatch }, payload) {
      await dispatch('loadUserTour', { restart: true })
        .then(response => {
          commit('setTourStep', 'main')
        })
    },
    async finishTour ({ commit, state, dispatch }, payload) {
      const result = await userTourFunc({
        userTourId: state.userTour.id,
        finish: true,
        ...payload
      })
      commit('setUserTour', {
        userTour: result.data.userTour
      })
    },
    async checkPayment ({ commit, dispatch }, payload) {
      await checkPaymentFunc({
        tourId: payload.tourId
      })
        .then(result => {
          if (result.data.paid) {
            commit('setTourPaid', true)
          } else if (result.data.status === 'waiting' && (!payload.retry || payload.retry < 3)) {
            setTimeout(() => {
              dispatch('checkPayment', {
                ...payload,
                retry: (payload.retry || 0) + 1
              })
            }, 5000)
          } else {
            commit('setTourPaid', false)
          }
        })
    },
    async checkVoucher ({ commit }, payload) {
      await checkVoucherFunc({
        tourId: payload.tourId
      })
        .then(result => {
          commit('setTourRedeemed', !!result.data.ok)
        })
    },
    openPost ({ commit, state, dispatch }, { slug, visit, unlock, force }) {
      // if tour had been started already, continue (no matter if post could be opened)
      if (state.tourStarted) {
        commit('setTourStep', 'main')
      }

      let openPostAllowed = state.userTour.visited.indexOf(slug) > -1 || !state.tour.posts_order_force || (!visit && state.tour.posts_open_unvisited) || force
      if (!openPostAllowed) {
        let prevIsVisited = true
        for (let p of state.tour.posts) {
          if (p.slug === slug) {
            openPostAllowed = prevIsVisited
            break
          }
          prevIsVisited = state.userTour.visited.indexOf(p.slug) > -1
        }
      }
      if (openPostAllowed) {
        const post = state.tour.posts.find(p => p.slug === slug)
        commit('setPost', { post })

        if (visit) {
          if (state.userTour.visited.indexOf(slug) === -1) {
            // update local state
            commit('setPostVisited', slug)

            // track server side
            postFunc({
              tourId: state.tour.tourId,
              userTourId: state.userTour.id,
              slug: slug
            })

            // unlock?
            if (post.qr_lock && unlock) {
              dispatch('unlockPost', slug)
            }
          }
        }
      } else {
        commit('setSnack', { snack: i18n.t('visit_posts_in_order') })
      }
    },
    unlockPost ({ commit, state }, slug) {
      if (state.userTour.unlocked.indexOf(slug) === -1) {
        // update local state
        commit('setPostUnlocked', slug)

        // track server side
        postFunc({
          tourId: state.tour.tourId,
          userTourId: state.userTour.id,
          slug: slug,
          unlock: true
        })
      }
    },
    openPoi ({ commit, state }, { slug, visit }) {
      // if tour had been started already, continue (no matter if post could be opened)
      if (state.tourStarted) {
        commit('setTourStep', 'main')
      }

      const poi = state.tour.pois.find(p => p.slug === slug)
      commit('setPoi', { poi })

      if (visit) {
        if (state.userTour.visited.indexOf(slug) === -1) {
          // update local state
          commit('setPostVisited', slug)

          // track server side
          postFunc({
            tourId: state.tour.tourId,
            userTourId: state.userTour.id,
            slug: slug
          })
        }
      }
    },
    async quizSubmit ({ commit, state }, payload) {
      await quizFunc({
        tourId: state.tour.tourId,
        userTourId: state.userTour.id,
        slug: payload.slug,
        lang: payload.lang,
        answer: payload.answer
      })
        .then(result => {
          commit('quizResult', {
            slug: payload.slug,
            submittedAnswer: payload.answer,
            ...result.data // correct, tries, [, correctAnswer, explainer]
          })
        })
    },
    async gameSubmit ({ commit, state }, payload) {
      await gameFunc({
        tourId: state.tour.tourId,
        userTourId: state.userTour.id,
        slug: payload.slug,
        ...payload
      })
        .then(result => {
          commit('gameResult', {
            slug: payload.slug,
            submittedAnswer: payload.answer,
            ...result.data // won, tries
          })
        })
    },
    async formSubmit ({ commit, state }, payload) {
      await formFunc({
        tourId: state.tour.tourId,
        userTourId: state.userTour.id,
        slug: payload.slug,
        lang: payload.lang,
        text: payload.text
      })
        .then(result => {
          commit('formResult', {
            slug: payload.slug,
            submittedText: payload.text,
            ...result.data // nothing yet
          })
        })
    },
    async recoverState ({ commit, state, dispatch }, payload) {
      function setVisited (slug) {
        // update local state
        commit('setPostVisited', slug)
        // track server side
        postFunc({
          tourId: state.tour.tourId,
          userTourId: state.userTour.id,
          slug: slug
        })
      }
      const visited = state.userTour && state.userTour.visited ? state.userTour.visited : []
      // posts
      const posts = []
      if (payload.nr) {
        for (let p = 0; p < Math.min(payload.nr, state.tour.posts.length); p++) {
          const post = state.tour.posts[p]
          if (!visited.includes(post.slug)) {
            posts.push(post)
          }
        }
      } else {
        payload.nrs.forEach(nr => {
          const post = state.tour.posts[nr - 1]
          if (!visited.includes(post.slug)) {
            console.log(nr - 1, post)
            posts.push(post)
          }
        })
      }
      posts.forEach(post => {
        setVisited(post.slug)
        // unlock?
        if (post.qr_lock) {
          dispatch('unlockPost', post.slug)
        }
      })
      // pois
      for (let p = 0; p < state.tour.pois.length; p++) {
        const poi = state.tour.pois[p]
        if (!visited.includes(poi.slug)) {
          setVisited(poi.slug)
        }
      }
      commit('setSnack', { snack: i18n.t('recover_done'), color: 'green' })

      const supportLogFunc = fn.httpsCallable('app-supportLog')
      await supportLogFunc({
        type: 'recover-state',
        tourId: state.tour.tourId,
        userTourId: state.userTour.id,
        ...payload
      })

      return true
    }
  }
})
