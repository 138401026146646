import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

// static redirects (route.path)
const REDIRECTS = [{
  from: '/event-tourismus/historischer-spazierfuhrer-burgdorf',
  to: '/burgdorf/historischer-spazierfuhrer-burgdorf'
},{
  from: '/berger-events/burgdorfer-gewerbekanale-tour',
  to: '/event-tourismus/burgdorfer-gewerbekanale-tour'
},{
  from: '/meine-berge/wasserweg-trail-1-rundweg-wasserfall',
  to: '/meine-berge/wasserweg-trail-1'
},{
  from: '/meine-berge/wasserweg-trail-2-unter-dem-birg-muhli',
  to: '/meine-berge/wasserweg-trail-2'
}/*,{
  from: '/sils/digitale-silser-dorffuhrung',
  to: '/engadin/digitale-silser-dorffuhrung'
}*/,{
  from: '/sils',
  to: '/engadin'
},{
  from: '/celerina/dorffuhrung',
  to: '/engadin/dorffuhrung-celerina',
},{
  from: '/celerina',
  to: '/engadin'
},{
  from: '/samedan/dorffuhrung',
  to: '/engadin/dorffuhrung-samedan',
},{
  from: '/samedan',
  to: '/engadin'
},{
  from: '/jungfrauregion/pioniere-im-wandel',
  to: '/jungfrauregion/smartrail-eigergletscher'
}]

const INITIAL_PROVIDER = 'initial_provider'

function saveInitialProvider (to, from, next) {
  if (from.name === null) { // initial app start / first route
    // new: save last provider directly opened by provider or tour
    // if (!localStorage.getItem(INITIAL_PROVIDER)) {
    localStorage.setItem(INITIAL_PROVIDER, to.params.provider)
    // }
  }
  next()
}

function beforeEner (to, from, next) {
  // check for redirects
  const redirect = REDIRECTS.find(r => to.path.indexOf(r.from) === 0)
  if (redirect) {
    // redirect
    window.location.href = window.location.href.replace(redirect.from, redirect.to)
  } else {
    // continue normally
    saveInitialProvider(to, from, next)
  }
}

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      beforeEnter: (to, from, next) => {
        let initialProvider = null
        if (from.name === null) { // initial app start
          // check if we have an initial provider saved
          initialProvider = localStorage.getItem(INITIAL_PROVIDER)
        }
        if (initialProvider) {
          next('/' + initialProvider)
        } else {
          // next()
          // go to website instead
          window.location.href = process.env.VUE_APP_HOME_URL
        }
      }
    },
    {
      path: '/account',
      name: 'account',
      // route level code-splitting
      // this generates a separate chunk (account.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "account" */ './views/Account.vue')
    },
    {
      path: '/:provider',
      name: 'provider',
      // route level code-splitting
      // this generates a separate chunk (provider.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "provider" */ './views/Provider.vue'),
      beforeEnter: beforeEner
    },
    {
      path: '/:provider/:tour/:post?',
      name: 'tour',
      // route level code-splitting
      // this generates a separate chunk (tour.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/* webpackChunkName: "tour" */ './views/Tour.vue'),
      beforeEnter: beforeEner
    }
  ]
})
