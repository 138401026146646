import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'


const USE_EMULATOR_SUITE = location.hostname === 'localhost'
const USE_LIVEDATA = false // set to true when developing locally with emulator-livedata to access live data via local emulated functions 
const LIVE = location.hostname.indexOf('test') === -1
const FUNCTIONS_REGION = LIVE ? 'europe-west1' : 'europe-west6'

const firebaseConfig = LIVE ? {
  apiKey: 'AIzaSyCilM7Urtd1wSzw21S33nLVKBkAfbqtDZ8',
  authDomain: 'app.smartrails.ch',
  databaseURL: 'https://noble-descent-263011.firebaseio.com',
  projectId: 'noble-descent-263011',
  storageBucket: 'noble-descent-263011.appspot.com',
  messagingSenderId: '642414255078',
  appId: '1:642414255078:web:24e268517153f12213d427',
  measurementId: 'G-8WNBD3F4DV'
} : {
  apiKey: 'AIzaSyDv3Qayshki5APzwLsBOYVSZ_JF721Ge9k',
  authDomain: 'smartrails-test-app.web.app',
  projectId: 'smartrails-test',
  storageBucket: 'smartrails-test.appspot.com',
  messagingSenderId: '215857158662',
  appId: '1:215857158662:web:a896d9f809744e376e4187'
}

const fb = firebase

const app = firebase.initializeApp(firebaseConfig)

const analytics = !USE_EMULATOR_SUITE && firebaseConfig.measurementId ? firebase.analytics() : null

const auth = firebase.auth()

const db = app.firestore()
if (USE_EMULATOR_SUITE && !USE_LIVEDATA) {
  db.useEmulator('localhost', 5002)
}

const fn = app.functions(FUNCTIONS_REGION)
if (USE_EMULATOR_SUITE) {
  fn.useEmulator('localhost', 5001)
}


export { fb, app, analytics, auth, fn }
