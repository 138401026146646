<template>
  <v-app :lang="$root.$i18n.locale">
    <v-navigation-drawer
      app
      temporary
      v-model="drawer"
      :touchless="!drawer"
    >
      <v-list-item class="pa-5">
        <!-- <v-img src="/img/logo.jpg" :aspect-ratio="800/147" width="100%"/> -->
        <v-img :src="logoSrc" width="100%"/>
      </v-list-item>

      <v-divider></v-divider>
      <v-list dense>
        <v-list-item @click="goHome">
          <v-list-item-icon>
            <v-icon>home</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-list-item to="/account">
          <v-list-item-icon>
            <v-icon>account_circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('account') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-icon>
            <v-icon>language</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="list-select">
            <v-select
              :items="localeItems"
              :value="$root.$i18n.locale"
              solo
              flat
              dense
              @change="$root.$i18n.setLocale($event); drawer = false"
            ></v-select>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="my-2"></v-divider>
        <v-list-item v-if="supportLink" :href="supportLink" target="_blank">
          <v-list-item-icon>
            <v-icon>help_outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('help') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else @click.stop="$store.commit('showSupportDlg', !$store.state.supportDlg)">
          <v-list-item-icon>
            <v-icon>help_outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('help') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :href="aboutLink" target="_blank">
          <v-list-item-icon>
            <v-icon>info</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('about') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="impressumLink" :href="impressumLink" target="_blank">
          <v-list-item-icon>
            <v-icon>home_work</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('imprint') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else @click.stop="$store.commit('showImprintDlg', true)">
          <v-list-item-icon>
            <v-icon>home_work</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('imprint') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-if="provider || tour" v-slot:append>
        <v-sheet color="secondary" class="pa-2">
          <author-info :provider="provider" :tour="tour" class="white--text text-caption"/>
        </v-sheet>
      </template>
    </v-navigation-drawer>

    <v-navigation-drawer
      v-if="$store.getters.isAdmin"
      app
      right
      temporary
      disable-resize-watcher
      v-model="adminDrawer"
      :touchless="!adminDrawer"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            {{ $t('admin') }}
          </v-list-item-title>
          <!-- <v-list-item-subtitle>
            subtext
          </v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-if="tour">
          <v-list-item-icon>
            <v-icon>my_location</v-icon>
          </v-list-item-icon>
          <v-list-item-content class="list-select">
            <v-select
              :items="tour ? tour.posts.map(p => ({
                text: p.title,
                value: p.slug
              })) : []"
              :label="$t('open_post')"
              dense
              solo
              flat
              @change="$store.dispatch('openPost', { slug: $event, visit: true })"
            ></v-select>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="tour" @click="$store.dispatch('loadUserTour', { reset: 'visited', visited: $store.state.tour.posts.map(p => p.slug) })">
          <v-list-item-icon>
            <v-icon>place</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('set_all_posts_visited') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="tour" @click="$store.dispatch('loadUserTour', { reset: 'visited', visited: [] })">
          <v-list-item-icon>
            <v-icon>place</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('set_all_posts_unvisited') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="tour" @click="$store.dispatch('loadUserTour', { reset: 'quizzes', quizzes: {} })">
          <v-list-item-icon>
            <v-icon>star</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('reset_quizzes') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="tour" @click="$store.dispatch('loadUserTour', { reset: 'games', games: {} })">
          <v-list-item-icon>
            <v-icon>star</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t('reset_games') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      app
      dark
      color="primary"
      :prominent="prominent"
      :shrink-on-scroll="prominent"
      scroll-target="#content">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <v-toolbar-title v-html="title"></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="$store.getters.isAdmin" icon @click.stop="adminDrawer = !adminDrawer">
        <v-icon>verified_user</v-icon>
      </v-btn>
      <v-btn v-if="supportLink" icon :href="supportLink" target="_blank">
        <v-icon>help_outline</v-icon>
      </v-btn>
      <v-btn v-else icon @click.stop="$store.commit('showSupportDlg', !$store.state.supportDlg)">
        <v-icon>help_outline</v-icon>
      </v-btn>

      <!-- <template v-slot:img="{ props }">
        <v-img
          src="https://cdn.vuetifyjs.com/images/parallax/material2.jpg"
          gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
        ></v-img>
      </template> -->
    </v-app-bar>

    <v-main id="content">
      <router-view @home="goHome"></router-view>
    </v-main>

    <v-dialog v-model="$store.state.supportDlg" fullscreen scrollable>
      <v-card class="dlg">
        <v-toolbar dark color="primary">
          <v-btn icon disabled></v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('help_center') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$store.commit('showSupportDlg', false)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-0">
          <div class="my-5">
            <v-container v-if="supportContents">
              <v-sheet v-if="provider || tour" color="secondary" class="pa-3 mb-5">
                <author-info :provider="provider" :tour="tour" v2 class="white--text text-caption"/>
              </v-sheet>

              <content-root :contents="supportContents"/>

              <self-service v-if="showSelfService" @close="$store.commit('showSupportDlg', false)" class="mt-14"/>

              <div v-if="supportContact" class="mt-14">
                <h2 class="mb-5 font-weight-bold">{{ $t('support_contact') }}</h2>
                <div class="multiline">{{ supportContact }}</div>
              </div>
            </v-container>

            <div v-else class="mt-10 text-center">
              <v-progress-circular indeterminate color="secondary"/>
            </div>
          </div>
          <div class="d-flex my-10">
            <v-spacer></v-spacer>
            <v-btn dark color="grey" @click="$store.commit('showSupportDlg', false)">{{ $t('close') }}</v-btn>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$store.state.imprintDlg" fullscreen scrollable>
      <v-card class="dlg">
        <v-toolbar dark color="primary">
          <v-btn icon disabled></v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title>{{ $t('imprint') }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="$store.commit('showImprintDlg', false)">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="px-0">
          <div class="my-5">
            <v-container v-if="imprintContents">
              <v-sheet v-if="provider || tour" color="secondary" class="pa-3 mb-5">
                <author-info :provider="provider" :tour="tour" v2 class="white--text text-caption"/>
              </v-sheet>

              <content-root :contents="imprintContents" execute-scripts/>
            </v-container>

            <div v-else class="mt-10 text-center">
              <v-progress-circular indeterminate color="secondary"/>
            </div>
          </div>
          <div class="d-flex my-10">
            <v-spacer></v-spacer>
            <v-btn dark color="grey" @click="$store.commit('showImprintDlg', false)">{{ $t('close') }}</v-btn>
            <v-spacer></v-spacer>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="showSnack" top :elevation="8" :color="snack && snack.color ? snack.color : 'error'">
      <div v-if="snack !== null">{{ snack.snack }}</div>
      <template v-slot:action>
        <v-btn text @click="showSnack = false">OK</v-btn>
      </template>
    </v-snackbar>

    <update-info @installing="$store.commit('setSnack', { snack: $t('update_installing'), color: 'info' })"/>
  </v-app>
</template>

<script>
import AuthorInfo from '@/components/AuthorInfo.vue'
import ContentRoot from '@/components/content/ContentRoot.vue'
import SelfService from '@/components/SelfService.vue'
import UpdateInfo from '@/components/UpdateInfo.vue'
import FileMixin from '@/mixins/FileMixin.js'
import UtilsMixin from '@/mixins/UtilsMixin.js'

const APP_TITLE = 'smarTrails<sup>&reg;</sup>'
const DOC_TITLE = 'smarTrails®'
const HELP_LANGS = ['de', 'en']

export default {
  name: 'App',
  mixins: [
    FileMixin,
    UtilsMixin
  ],
  components: {
    AuthorInfo,
    ContentRoot,
    SelfService,
    UpdateInfo
  },
  data () {
    return {
      drawer: null, // closed on mobile, open on desktop
      adminDrawer: false,
      showSnack: false
    }
  },
  computed: {
    title () {
      if (this.$route.name === null || this.$route.name === 'home') {
        return APP_TITLE
      } else if (this.$route.name === 'provider') {
        return this.$store.state.provider ? this.$store.state.provider.name : ''
      } else if (this.$route.name === 'tour') {
        if (this.$store.state.tour?.timer && this.$store.state.userTour?.started) {
          return ''
        } else {
          return this.$store.state.tour ? this.$store.state.tour.title : ''
        }
      } else {
        let name = this.$route.name
        return this.$te(name) ? this.$t(name) : name.charAt(0).toUpperCase() + name.slice(1)
      }
    },
    prominent () {
      return false // TODO: add nice bg img to be useful first, then: this.$route.name === null || this.$route.name === 'home'
    },
    localeItems () {
      return this.$root.$i18n.locales.map(l => ({
        text: this.$t(l),
        value: l
      }))
    },
    snack () {
      return this.$store.state.snack
    },
    // helpContents () {
    //   if (!this.$store.state.settings) return null
    //   const lang = HELP_LANGS.includes(this.$root.$i18n.locale) ? this.$root.$i18n.locale : 'en'
    //   const help = this.$store.state.settings['help__' + lang]
    //   return help && help.contents ? help.contents : null
    // },
    supportContents () {
      if (!this.$store.state.settings) return null
      const lang = HELP_LANGS.includes(this.$root.$i18n.locale) ? this.$root.$i18n.locale : 'en'
      const support = this.$store.state.settings['support__' + lang]
      return support && support.contents ? support.contents : null
    },
    imprintContents () {
      if (!this.$store.state.settings) return null
      const lang = HELP_LANGS.includes(this.$root.$i18n.locale) ? this.$root.$i18n.locale : 'en'
      const imprint = this.$store.state.settings['imprint__' + lang]
      return imprint && imprint.contents ? imprint.contents : null
    },
    provider () {
      return this.$store.state.provider
    },
    tour () {
      return this.$store.state.tour
    },
    supportContact () {
      return this.tour?.support
    },
    showSelfService () {
      return (this.tour?.selfservice || this.tour?.selfservice === undefined) && this.$store.state.tourStarted && this.$store.state.tourStep === 'main'
    },
    logoSrc () {
      const wl_logo = this.provider?.wl_logo || this.tour?.wl_logo
      if (wl_logo) {
        return this.imgSrc('contents/root/' + wl_logo, 216)
      } else {
        return '/img/logo.jpg'
      }
    },
    aboutLink () {
      return this.provider?.wl_link_about || this.tour?.wl_link_about || 'https://tourify.ch/smartrails'
    },
    supportLink () {
      return this.provider?.wl_link_support || this.tour?.wl_link_support
    },
    impressumLink () {
      return this.provider?.wl_link_imprint || this.tour?.wl_link_imprint
    }
  },
  methods: {
    goHome () {
      if (this.tour) {
        this.$store.commit('setPreventAutoContinue', true)
        this.$store.commit('setTourStep', 'welcome')
        if (this.$route.path !== '/' + this.tour.slug) {
          this.$router.push('/' + this.tour.slug)
        }
      } else if (this.provider) {
        this.$router.push('/' + this.provider.slug)
      } else {
        const initialProvider = localStorage.getItem('initial_provider')
        this.$router.push('/' + (initialProvider || ''))
      }
      this.drawer = false
    },
    onResize () {
      if (window.innerWidth && window.innerHeight) {
        document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
        this.$store.commit('setOrientation', window.innerWidth < window.innerHeight ? 'portrait' : 'landscape')
      }
    },
    applyWhitelabel () {
      const rootStyle = document.documentElement.style // const rootStyle = document.querySelector(':root').style
      const wl_color_primary = this.provider?.wl_color_primary || this.tour?.wl_color_primary
      if (wl_color_primary) {
        rootStyle.setProperty('--v-primary-base', wl_color_primary)
      }
      const wl_color_secondary = this.provider?.wl_color_secondary || this.tour?.wl_color_secondary
      if (wl_color_secondary) {
        rootStyle.setProperty('--v-secondary-base', wl_color_secondary)
      }
      const wl_styles = this.provider?.wl_styles || this.tour?.wl_styles
      if (wl_styles) {
        const styleTag = '<style>' + wl_styles + '</style>'
        document.getElementsByTagName('head')[0].insertAdjacentHTML('beforeend', styleTag)
      }
    }
  },
  watch: {
    title (title) {
      window.document.title = title === APP_TITLE
        ? DOC_TITLE
        : DOC_TITLE + ' - ' + title
    },
    snack (val) {
      if (val !== null) {
        this.showSnack = true
      }
    },
    showSnack (val) {
      if (val === null) {
        this.$store.commit('setSnack', null)
      }
    },
    '$store.state.supportDlg' (val) {
      if (val && !this.$store.state.settings) {
        this.$store.dispatch('loadSettings')
      }
      if (window.tidioChatApi) {
        if (val) {
          window.tidioChatApi.show()
        } else {
          window.tidioChatApi.hide()
        }
      }
    },
    '$store.state.imprintDlg' (val) {
      if (val && !this.$store.state.settings) {
        this.$store.dispatch('loadSettings')
      }
    },
    provider (val) {
      if (val) {
        this.applyWhitelabel()
      }
    },
    tour (val) {
      if (val) {
        this.applyWhitelabel()
      }
    }
  },
  created () {
    // set orientation initially
    this.onResize()
    // listen for resize event to detect orientation changes
    window.addEventListener('resize', () => {
      this.onResize()
    })

    // online - offline listeners
    window.addEventListener('online', () => {
      this.$store.commit('setOnline', true)
    })
    window.addEventListener('offline', () => {
      this.$store.commit('setOnline', false)
    })

    // tidio chat listeners
    function onTidioChatApiReady() {
      window.tidioChatApi.hide()
    }
    if (window.tidioChatApi) {
      window.tidioChatApi.on('ready', onTidioChatApiReady)
    } else {
      document.addEventListener('tidioChat-ready', onTidioChatApiReady)
    }
  }
}
</script>

<style scoped>
.maintitle {
  background: var(--v-secondary-base);
}
.list-select {
  padding: 0 !important;
}
.list-select >>> .v-label {
  font-size: 13px;
  font-weight: 500;
  color: inherit;
}
.list-select >>> .v-select {
  font-size: 13px;
  font-weight: 500;
  margin-top: -3px;
}
.list-select >>> .v-input__slot {
  padding: 0 20px 0 0 !important;
  margin: 0 !important;
}
.list-select >>> .v-text-field__details {
  padding: 0 20px 0 0 !important;
  margin: 0 !important;
  min-height: 0 !important;
  height: 0 !important;
}
</style>
