import Vue from 'vue'
import VueI18n from 'vue-i18n'
import labels from '@/labels.js'

Vue.use(VueI18n)

// define available languages; first is default
const locales = ['en', 'de', 'fr', 'it'] // , 'fr', 'it', 'es', 'ja'

// lang - currently saved in local storage
let lang = localStorage.getItem('lang')
// if not set, use browser lang, if available
if (!lang) {
  let naviLang = navigator.language.substr(0, 2)
  if (locales.indexOf(naviLang) !== -1) {
    lang = naviLang
  }
}
// else take default lang
if (!lang) {
  lang = locales[0]
}

const i18n = new VueI18n({
  locale: lang,
  fallbackLocale: locales[0],
  silentFallbackWarn: true,
  messages: labels
})

// provide list of available langs to app
i18n.locales = locales

// use this function to switch lang
i18n.setLocale = function (lang) {
  if (locales.indexOf(lang) !== -1) {
    i18n.locale = lang
    localStorage.setItem('lang', lang)
  }
}

export default i18n
