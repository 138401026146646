/**
 * utility functions, like device detection
 */

import UAParser from 'ua-parser-js'

const ua = UAParser()

export default {
  computed: {
    chrome () {
      return ['Chrome', 'Edge'].includes(ua.browser.name)
    },
    ios () {
      return ua.os.name === 'iOS'
    },
    safari () {
      return ['Safari', 'Mobile Safari'].includes(ua.browser.name)
    },
    isBrowserSupported () {
      return this.chrome || this.safari
    },
    ua () {
      return ua
    }
  }
}
