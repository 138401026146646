import { render, staticRenderFns } from "./ImageCard.vue?vue&type=template&id=48c489fe&scoped=true"
import script from "./ImageCard.vue?vue&type=script&lang=js"
export * from "./ImageCard.vue?vue&type=script&lang=js"
import style0 from "./ImageCard.vue?vue&type=style&index=0&id=48c489fe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.7.3_webpack@5.80.0__ejs@3.1.9_lodash@4.17.21_vue-template-compiler@2.7.14_webpack@5.80.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c489fe",
  null
  
)

export default component.exports