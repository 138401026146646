import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n'
import './plugins/googlemaps'
import './plugins/reactiveprovide'
import './registerServiceWorker'
import { initAuth } from './auth'
import '@/assets/styles.css'

Vue.config.productionTip = false

new Vue({
  store,
  router,
  vuetify,
  i18n,
  data () {
    return {
      preloads: []
    }
  },
  created () {
    initAuth()
  },
  render: function (h) { return h(App) }
}).$mount('#app')
